import React from 'react';

const NoPerfume = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32.441"
    height="48.812"
    viewBox="0 0 32.441 48.812"
  >
    <defs>
      <clipPath>
        <rect width="32.441" height="48.812" fill="currentColor" />
      </clipPath>
    </defs>
    <path
      d="M0,56.218l1.713,1.359L32.441,13.7l-1.713-1.359Z"
      transform="translate(0 -9.821)"
      fill="currentColor"
    />
    <g>
      <g clipPath="url(#clip-path)">
        <path
          d="M20.377,0c.121.07.243.137.362.21a1.389,1.389,0,0,1,.413,2.03A6.109,6.109,0,0,0,20.524,8.8a12.106,12.106,0,0,0,2.32,3.526C25.1,14.735,27.4,17.1,29.7,19.471a41.11,41.11,0,0,1,6.205,7.691,15.378,15.378,0,0,1,2.311,8.8,16.087,16.087,0,0,1-3.408,9.082,32.059,32.059,0,0,1-3.158,3.352,1.36,1.36,0,0,1-2.1-.164,1.327,1.327,0,0,1-.07-1.637A6.383,6.383,0,0,0,30,39.752a17.513,17.513,0,0,0-3.3-4.64c-2.032-2.143-4.129-4.224-6.19-6.34a44.13,44.13,0,0,1-5.565-6.75c-3.977-6.143-2.948-13.08.828-18.18a27.919,27.919,0,0,1,2.391-2.709A12.62,12.62,0,0,1,19.614,0Zm-3.31,7.085a12.9,12.9,0,0,0-1.84,5.876,13.048,13.048,0,0,0,2.255,7.586,45.465,45.465,0,0,0,6.035,7.219c2.118,2.212,4.252,4.411,6.295,6.691a15.907,15.907,0,0,1,3.36,5.593c.166.5.288,1.017.447,1.59.112-.184.2-.31.271-.445a11.75,11.75,0,0,0,.193-11.357,29.023,29.023,0,0,0-5.109-6.9c-2.344-2.48-4.772-4.882-7.08-7.395-2.21-2.407-4.156-5-4.826-8.459"
          transform="translate(-9.882 -0.001)"
          fill="currentColor"
        />
        <path
          d="M15.478,141.086a5.429,5.429,0,0,1-1.2,3.451,1.371,1.371,0,0,1-1.674.405,1.414,1.414,0,0,1-.834-1.512,1.3,1.3,0,0,1,.219-.568,2.737,2.737,0,0,0-.035-3.561,17.98,17.98,0,0,0-2.165-2.392c-1.773-1.659-3.644-3.214-5.39-4.9A9.862,9.862,0,0,1,1.455,127.2a8.339,8.339,0,0,1-.206-1.884,1.419,1.419,0,1,1,2.837-.044,7,7,0,0,0,2.4,4.825c1.568,1.526,3.283,2.9,4.874,4.4a34.249,34.249,0,0,1,2.984,3.188,5.3,5.3,0,0,1,1.138,3.4"
          transform="translate(-0.994 -98.578)"
          fill="currentColor"
        />
        <path
          d="M94.687,30.51c.1,1.386-.8,2.224-1.87,1.845a1.276,1.276,0,0,1-.941-1.2A6.9,6.9,0,0,0,89.6,26.326c-1.376-1.359-2.884-2.584-4.3-3.907a41.872,41.872,0,0,1-3.328-3.326,5.649,5.649,0,0,1-.325-7.308,1.357,1.357,0,0,1,1.982-.275,1.4,1.4,0,0,1,.25,2.025,2.636,2.636,0,0,0-.228,2.914A9.279,9.279,0,0,0,85.968,19.2c1.547,1.366,3.16,2.663,4.648,4.089a23.135,23.135,0,0,1,2.618,3.071,7.6,7.6,0,0,1,1.452,4.146"
          transform="translate(-64.046 -8.915)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default NoPerfume;
